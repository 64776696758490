<!-- Begin Page Content -->
<div class="container-fluid">

    <!-- DataTales Example -->
    <div class="card shadow mb-4">
        <div class="card-header d-sm-flex align-items-center justify-content-between py-3">
            <h1 class="h3 mb-0 text-gray-800">Users</h1>
            <button (click)="ExportTOExcel()" class="d-none d-sm-inline-block btn btn-sm btn-success shadow-sm"
                style="float: right;">Export To Excel</button>
            <!-- <a routerLink="/admin/stylists-add" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">Add New Stylists</a> -->
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table datatable [dtOptions]="dtOptions" class="table table-bordered hover" width="100%"
                    cellspacing="0">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Username</th>
                            <th>Phone Number</th>
                            <th>Email</th>
                            <th>Venue Package Choosen</th>
                            <th>Subscription Expiry</th>
                            <th>User Status</th>
                            <th>Action</th>

                        </tr>
                    </thead>
                    <tbody *ngIf="customers?.length != 0">
                        <tr *ngFor="let customer of customers">
                            <td class="name">{{ (customer.name !== '') ? customer.name : 'N/A' }}</td>
                            <td class="username">{{ (customer.username !== '') ? customer.username : 'N/A'
                            }} </td>
                            <td class="phone_number">{{ (customer.phone_number !== '') ? customer.phone_number : 'N/A'
                                }} </td>
                            <td class="email">{{ (customer.email !== '') ? customer.email : 'N/A' }}</td>
                            <td class="package">{{ (customer.subscription.package?.name) ? customer.subscription.package.name : 'N/A' }}</td>
                            <td class="subscription_expiry">{{ (customer.subscription.expiration_date) ? (customer.subscription.expiration_date | date) :
                                'N/A' }} </td>
                            <td>{{customer.status}}</td>
                            <td style="width: 15%;">
                                <a routerLink="/user/{{ customer._id }}" class="btn btn-primary btn-sm">View</a>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="customers?.length == 0">
                        <tr>
                            <td colspan="3" class="no-data-available">No records found!</td>
                        </tr>
                    <tbody>
                </table>
            </div>
        </div>
    </div>

</div>
<!-- /.container-fluid -->


<!-- for excel export -->

<div class="table-responsive" #TABLE #table>

    <table class="table table-bordered hover" width="100%" cellspacing="0" style="display: none;">
        <thead>
            <tr>
                <th>Sr No</th>
                <th>Name</th>
                <th>QR Code String (As Url)</th>
            </tr>
        </thead>
        <tbody *ngIf="reportsExport?.length != 0">
            <tr *ngFor="let excelReport of reportsExport; let i =index">
                <td>{{i+1}}</td>
                <td>{{excelReport.name}}</td>
                <td>{{  qrCodeBaseUrl+'/'+excelReport._id}}</td>
            </tr>
        </tbody>
    </table>
</div>