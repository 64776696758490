<!-- Begin Page Content -->
<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <!-- Default Card Example -->
            <div class="card mb-6">
                <div class="card-header">
                    User Details
                    <div style="text-align:right;">
                        <!-- <input type="button" value="Export 1" (click)="downloadPdf('html-pdf2')" class="btn btn-primary" />
                        <input type="button" value="Export 2" (click)="downloadPdf2('html-pdf2')" class="btn btn-primary" style="margin-left: 10px;" /> -->
                        <button *ngIf="isActive"  [class]="buttonCss"
                        style="margin-left: 10px;" id="disable">{{buttonValue}}</button>
                        <button *ngIf="!isActive" (click)="changeStatus(userId,isActive)" [class]="buttonCss"
                        style="margin-left: 10px;">{{buttonValue}}</button>
                        <button *ngIf="venueSubscrBtnVisibility" (click)="changeVenueSubscrFormStatusModal(userId, updateSubscrStatusTo)" [class]="updateSubscrBtnClass"
                        style="margin-left:10px">{{venueSubscriptionBtn}}</button>
                    </div>

                </div>

                <div class="form-row p-3">
                    <div class="form-group col-lg-6">
                        <label>Name</label>
                        <input type="text" class="form-control capitalize" placeholder="Name" value="{{ (userDetails.name !== '') ? userDetails.name : 'N/A' }}" readonly>
                    </div>
                    <div class="form-group col-lg-6">
                        <label>Email</label>
                        <input type="text" class="form-control" placeholder="Email" value="{{ (userDetails.email !== '') ? userDetails.email : 'N/A' }}" readonly>
                    </div>
                    <div class="form-group col-lg-6">
                        <label>Phone</label>
                        <input type="text" class="form-control" placeholder="Phone" value="{{ (userDetails.phone_number !== '') ? userDetails.phone_number : 'N/A' }}" readonly>
                    </div>

                    <div class="form-group col-lg-6">
                        <label>Age</label>
                        <input type="text" class="form-control" placeholder="Age" value="{{ (userDetails.age !== '') ? userDetails.age : 'N/A' }}" readonly>
                    </div>

                    <div class="form-group col-lg-6">
                        <label>Gender</label>
                        <input type="text" class="form-control capitalize" placeholder="Gender" value="{{ (userDetails.gender !== '') ? userDetails.gender : 'N/A' }}" readonly>
                    </div>

                    <div class="form-group col-lg-6">
                        <label>What are you looking for?</label>
                        <input type="text" class="form-control capitalize" placeholder="Looking for" value="{{ (userDetails.want_to_meet !== '') ? userDetails.want_to_meet : 'N/A' }}" readonly>
                    </div>

                    <div class="form-group col-12 mt-2">
                        <h6>Venue Subscription Detail</h6>
                        <form [formGroup]="subscriptionForm" (ngSubmit)="onSubmitSubscription()">
                            <div class="form-row">
                                <div class="form-group col-lg-6">
                                    <label>Package Choosen </label>
                                    
                                    <select formControlName="package" class="form-select" (change)="onSelectPackage($event)">
                                        <option value="null" disabled>Package Name</option>
                                        <option *ngFor="let option of venueSubscrPackages" [value]="option._id">{{option.name}} {{getVenueInterval(option.interval)}}</option>
                                    </select>
                                </div>
                                
                                <div class="form-group col-lg-6">
                                    <label>Venues allowed </label>
                                    <input type="text" class="form-control" placeholder="Venues allowed"
                                    [value]="venuesCount" [readonly]="true">
                                </div>
    
                                <div class="form-group col-lg-6">
                                    <label>Purchase Date</label>
                                    <input type="date" [min]="prevMonthDate" formControlName="purchaseDate" class="form-control" placeholder="dd-mm-YYYY   ">
                                </div>
                                
                                <div class="form-group col-lg-6">
                                    <label>Squarespace Fullfillment Date</label>
                                    <input type="date" [min]="prevMonthDate" formControlName="fullfillmentDate" class="form-control" placeholder="Fullfillment Date">
                                </div>
                                
                                <div class="form-group col-lg-6">
                                    <label>Expiration Date </label>
                                    <input type="date" [min]="currDate" formControlName="expirationDate" class="form-control" placeholder="Expiration Date">
                                    <small class="text-secondary" *ngIf="expectedDate">Expected Date - {{expectedDate}}</small>
                                </div>
                                
                                <div class="form-group col-lg-6">
                                    <label [ngClass]="{'text-danger': pauseComment !== ''}">Last Pause Reason</label>
                                    <textarea class="form-control" placeholder="Comment"
                                        value="{{ (pauseComment) ? pauseComment : 'N/A' }}"
                                        [readonly]="true"></textarea>
                                </div>

                                <div class="form-group col-lg-6">
                                    <button type="submit" class="btn btn-primary" [disabled]="!subscriptionForm.valid">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="form-group col-lg-6">
                        <!-- <qrcode [qrdata]="userProfile " [width]="256 " [errorCorrectionLevel]=" 'M' " [elementType]=" 'svg' "></qrcode> -->
                        <qrcode [qrdata]="userProfile" ></qrcode>
                    </div>
                    <div class="form-group col-lg-6" id="pdf">
                        <table style="width: 144px;font-family: avenir;font-weight: 600;" id="html-pdf">
                            <tbody>
                                <tr>
                                    <td><img src="assets/v1/img/image.svg " height="500 px " width="350 px "></td>
                                </tr>
                                <tr>
                                    <td style="font-size: 48px;color: #231F20; ">Hi,</td>
                                </tr>
                                <tr>
                                    <td style=" font-size: 36px; color: #231F20; ">I'm <span style=" color: #23B6A0; ">{{userDetails.name}}.</span> </td>
                                </tr>
                                <tr style="margin-left: 50px; ">
                                    <td style=" padding: 10px 0 20px; text-align: center; margin-left: 20px; ">
                                        <!-- <qrcode [qrdata]="userProfile " [width]="256 " [errorCorrectionLevel]=" 'M' " [elementType]=" 'svg' "></qrcode> -->

                                        <qrcode [qrdata]="userProfile"></qrcode>

                                    </td>
                                </tr>
                                <tr>
                                    <td style=" text-align: center; "><span style=" font-size: 28px; padding-right: 4px;color: #231F20; ">You're On My</span>
                                        <img src="assets/v1/img/onmyradar.svg ">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="form-group col-lg-6" id="pdf2">
                        <table style="width: 144px;font-family: avenir;font-weight: 600;" id="html-pdf2">
                            <tbody>
                                <tr>
                                    <td><img src="assets/v1/img/image.svg " height="705 px " width="510 px "></td>
                                </tr>
                            </tbody>
                        </table>
                        <table style="width: 144px;font-family: avenir;font-weight: 600;" id="html-pdf3">
                            <tbody>
                                <tr>
                                    <td style="font-size: 40px;color: #231F20; ">Hi,</td>
                                </tr>
                                <tr>
                                    <td style=" font-size: 30px; color: #231F20; ">I'm <span style=" color: #23B6A0; ">{{userDetails.name}}.</span> </td>
                                </tr>
                                <tr style="margin-left: 50px; ">
                                    <td style=" padding: 10px 0 20px; text-align: center; margin-left: 20px; ">
                                        <qrcode [qrdata]="userProfile " ></qrcode>
                                    </td>
                                </tr>
                                <tr>
                                    <td style=" text-align: center; "><span style=" font-size: 20px; padding-right: 4px;color: #231F20; ">You're On My</span>
                                        <img src="assets/v1/img/onmyradar.svg ">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="card mb-6">
                
            </div>

            <div class="card mb-6">
                <div class="card-header">Images{{imageArr}}</div>
                <div class="form-row p-3">
                    <ul>
                        <li *ngFor="let image of imageArr" style="display: inline-block!important;padding: 5px;"><img [src]="image" height="100px" width="100px"></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal popup -->
    <div id="openModal" class="modal fade addElementModal" tabindex="-1" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{modalTitle}} Venue Subscription</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p> Are you sure you want to {{modalTitle}} the Venue Subscription?</p>
                    <div *ngIf="venueSubscrStatus == 2" class="form-group">
                        <label>Enter Reason</label>
                        <textarea rows="4" class="form-control" placeholder="Enter Reason" [(ngModel)]="comment"
                            (ngModelChange)="onReasonTextChange($event)"></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" (click)="updateVenueSubscrFormStatus(userId,venueSubscrStatus)"
                    [disabled]="venueSubscrStatus == 2 && !comment" [ngClass]="getClassName(venueSubscrStatus)">{{modalTitle}}</button>
                </div>
            </div>
        </div>
    </div>

    <!--Popup content-->
    <div id="modal">
        <div id="heading-modal">
            Are you sure you want to disable/block user?
        </div>

        <div id="content-modal">
            <p></p>
            <form style="margin-left:80px">
                <!--textarea #reason class="form-control" rows="3"></textarea-->
                <!-- <a href="#" class="button-modal green close"><img src="assets/v1/img/tick.png">Yes, do it now!</a> -->
                <button class="btn btn-danger" (click)="changeStatus(userId,isActive)" style="margin-right: 10px;">Disable</button>
                <button class="btn btn-primary close">Close</button>
                
            </form>

            <!-- <a href="#" class="button-modal red close"><img src="assets/v1/img/cross.png">No, I’m insane!</a> -->
        </div>
    </div>
</div>