import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
export const TOKEN_NAME: string = 'jwt_token';
import { environment } from '../../environments/environment';
const AUTH_PREFIX = 'Bearer';
import { retry, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private headers;
  reqHeader: any;
  token: any;

  constructor(private httpClient: HttpClient) { }

  setHeader() {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${AUTH_PREFIX} ${localStorage.getItem(TOKEN_NAME)}`
    });
  }

  userListing(data) {
    // console.log(data);
    this.setHeader();
    var page = 1;
    if (data.start == 0) {
      var page = 1;
    } else {
      var page = (data.start / 10) + 1;
    }

    if (data.order[0].column == 0) {
      var column = "name";
    } else if (data.order[0].column == 1) {
      var column = "username";
    } else if (data.order[0].column == 2) {
      var column = "phone_number";
    }
    else if (data.order[0].column == 3) {
      var column = "email";
    }
    else if (data.order[0].column == 4) {
      var column = "subscription.package";
    }
    else if (data.order[0].column == 5) {
      var column = "subscription.expiration_date";
    }
    else {
      var column = "";
    };

    // console.log(data);
    var orderData = {};
    if (column) {
      orderData = { "column": column, "dir": data.order[0].dir };
    } else {
      orderData = "";
    };

    return this.httpClient.post<any>(`${environment.apiUrl}/admin/users`,
      { "page": page, "limit": data.length, "search_value": new String(data.search.value), "order": orderData },
      { headers: this.headers }
    ).
      pipe(catchError(this.handleError));
  }

  getExcelReportList(data) {

    var page = 1;
    return this.httpClient.post<any>(`${environment.apiUrl}/admin/users`,
      { "page": page, "limit": 100000, "search_value": new String(data.search.value), "order": undefined },
      { headers: this.headers }
    ).
      pipe(catchError(this.handleError));
  }

  user_detail(userId) {
    this.setHeader();
    return this.httpClient.post<any>(`${environment.apiUrl}/admin/user-detail`,
      { "user_id": userId },
      { "headers": this.headers }
    ).
      pipe(catchError(this.handleError));
  }

  countData() {
    this.setHeader();
    return this.httpClient.get(`${environment.apiUrl}/admin/get-count`, { headers: this.headers }).pipe(retry(1));
  }

  handleError(error: HttpErrorResponse) {
    console.log(`Status:${error.status}`);
    return throwError(error);
  }
}
