import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

import { UsersService } from '../_services/users.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

import * as XLSX from 'xlsx';

interface Customer {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
}

class DataTablesResponse {
  data: any[]=[];
  draw: number=0;
  recordsFiltered: number=0;
  recordsTotal: number=0;
}

class Export {
  id: number=0;
  firstName: string="";
  lastName: string="";
}

class Report {
  id: number;
  titile: string;
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  customers: Customer[];

  qrCodeBaseUrl = `${environment.qrCodeBaseUrl}`;

  constructor(
    private userService: UsersService,
    private toastr: ToastrService) { }

  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  search_key: string;
  title = 'Excel';
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'qr_codes_sheet.xlsx');
  }

  dtTrigger: Subject<any> = new Subject();
  reports: Report[];
  reportsExport: Export[];  

  ngOnInit(): void {
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      order: [[4, 'desc']],
      language: {
        searchPlaceholder: 'Name|Phone|Email'
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.search_key = dataTablesParameters.search.value;
        // that.searchFunction(this.search_key);
        this.userService.userListing(dataTablesParameters).
          subscribe(resp => {
            that.customers = resp.data.user_listing;
            // console.log(resp);
            callback({
              recordsTotal: resp.data.total_count,
              recordsFiltered: resp.data.filteredData,
              data: []
            });

            // Calling the DT trigger to manually render the table
            // this.dtTrigger.next();
          }, err => {
            // console.log(err);
            this.toastr.error(err.error);
            callback({
              processing: false,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: []
            });
          });
      },
      columns: [{ data: 'name' }, { data: 'username' }, { data: 'phone_number' }, { data: 'email' }, { data: 'subscription.package' }, { data: 'subscription.expiration_date' }]
      // columnDefs: [{ "orderable": false, "targets": 5 }]

    };

    let data_filter = { search: { value: '' } };
    // this.userService.getExcelReportList(data_filter)
    //   .subscribe(
    //     resp1 => {
    //       this.reportsExport = resp1.data.user_listing;
    //     });


  }

  searchFunction(search_key = null) {

    let data_filter = { search: { value: search_key } };
    this.userService.getExcelReportList(data_filter)
      .subscribe(
        resp1 => {
          this.reportsExport = resp1.data.user_listing;
        });
  }



}
