import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {UsersService} from '../_services/users.service';
import {VenueSubscriptionService} from '../_services/venue-subscription.service';
import {environment} from '../../environments/environment';
import {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';
import {AwsService} from '../_services/aws.service';
import {WebService} from '../_services/web.service';
import { ToastrService } from 'ngx-toastr';
import { PackagesList } from '../_models/venue-subscription.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VENUE_INTERVAL } from '../_constants/venue.constants';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {
  public myAngularxQrCode: string = null;
  userId:String;
  userDetails:any={};
  userProfile:String=null;
  userName:String=null;
  phone:String=null;
  imageArr:Array<any>=[];
  testArr:Array<any>=[12,3,4];
  isActive:boolean=true;
  buttonValue:string="";
  buttonCss:string="";
  venueSubscrPackages: PackagesList[] = [];
  venuesCount:String = '';
  subscriptionForm: FormGroup;
  currDate:any = new Date().toJSON().slice(0,10);
  prevMonthDate:any = new Date(new Date().setMonth(new Date().getMonth()-1)).toJSON().slice(0,10);
  expectedDate:any;
  venueSubscriptionBtn:string = "";
  updateSubscrStatusTo:number;
  updateSubscrBtnClass:string;
  venueSubscrBtnVisibility:boolean = false;
  modalTitle: string;
  venueSubscrStatus: number;
  comment: string;
  pauseComment: string;
  constructor(private route:ActivatedRoute, private userService: UsersService, private awsService:AwsService, private webService:WebService,private toastr: ToastrService, private venueSubscrService: VenueSubscriptionService,
    private fb:FormBuilder) {
    this.myAngularxQrCode = 'Radar.com';
    this.subscriptionForm = this.fb.group({
      purchaseDate: ['', Validators.required],
      fullfillmentDate: ['', Validators.required],
      expirationDate: ['', Validators.required],
      package: [null, Validators.required],
    });
   }

  ngOnInit(): void {
    let id= this.route.snapshot.params.id
    this.userId= id

    this.userService.user_detail(this.userId).subscribe(res=>{
      
      this.userDetails=res.data;
      this.userProfile=`${environment.userProfile}/${this.userId}`;

      this.isActive=res.data.is_active?true:false;
      this.buttonValue=res.data.is_active?"Deactivate":"Activate";
      this.buttonCss=res.data.is_active?"btn btn-danger":"btn btn-success"

      console.log('===>>>',this.userDetails.images);
      if(this.userDetails.images && this.userDetails.images.length){
        let images=this.userDetails.images;
        images.forEach(image =>{
          if(image){
            this.awsService.getImage(image).then(url=>{
              this.imageArr.push(url);
            })
          }
        });
        // if(imageKey){
        //   this.awsService.getImage(imageKey).then(url=>{
        //     this.profilePicUrl=url;
        //   })
        // } else{
        //   this.profilePicUrl="assets/images/profileDummy.png";
        // }          
      }
      // update subscription form field values
      if(this.userDetails.venue_subscription && this.userDetails.venue_subscription.purchase_date) {
        this.subscriptionForm.patchValue({
          purchaseDate: this.userDetails.venue_subscription.purchase_date,
          fullfillmentDate: this.userDetails.venue_subscription.fullfillment_date,
          expirationDate: this.userDetails.venue_subscription.expiration_date,
          package: this.userDetails.venue_subscription.package,
        });
        if(this.userDetails.venue_subscription.expiration_date && this.userDetails.venue_subscription.expiration_date >= this.currDate) {
          this.venueSubscrBtnVisibility = true;
        }
        this.changeSubscrFormLayout(this.userDetails.venue_subscription.status);
        this.venuesCount = this.userDetails.venue_subscription.venues_count;
        this.pauseComment = this.userDetails.venue_subscription.pause_comment;
      }

      localStorage.setItem('userName',res.data.name);
      localStorage.setItem('phone',res.data.phone_number);
    });
    // get venue subscription packages list
    this.venueSubscriptionPackages();
    this.detectSubscriptionChanges();
  }

  fillZero(val:number) {
    return val < 10 ? `0${val}` : val;
  }

  detectSubscriptionChanges() {
    this.subscriptionForm.valueChanges.subscribe(res => {
      if(res.purchaseDate && res.package && this.venueSubscrPackages.length) {
        let selectedPackage = this.venueSubscrPackages.find(item => item._id == res.package);
        this.expectedDate = this.getExpiryDate(selectedPackage.interval) || '';
      }
    }, err => {
      //
    })
  }

  venueSubscriptionPackages() {
    this.venueSubscrService.getPackagesList().subscribe({
      next:(res: any) => {
        this.venueSubscrPackages = res.data;
      },
      error: (err) => {
        this.venueSubscrService.handleError(err);
      }
    })
  }

  onSubmitSubscription() {
    let data = this.subscriptionForm.value;
    data.user_id = this.route.snapshot.params.id;
    this.venueSubscrService.updateUserSubscription(data).subscribe(res => {
      setTimeout(() => {
        location.reload();
      }, 1000);
      this.toastr.success(res.message);
    },
    err => {
      const errMsg = (err.name == "HttpErrorResponse") ? err.error.message : err.message
      this.toastr.error(errMsg);
    });
  }

  onSelectPackage(evt:any) {
    let pkgId = evt.target.value;
    let selectedPackage = this.venueSubscrPackages.find(item => item._id == pkgId);
    this.venuesCount = selectedPackage.size;
    this.expectedDate = this.getExpiryDate(selectedPackage.interval);
  }

  getExpiryDate(interval:String) {
    if(!this.subscriptionForm.get('purchaseDate').value) {
      return;
    }
    let purchaseDate = new Date(this.subscriptionForm.get('purchaseDate').value);
    let calcExpiryDate = new Date(purchaseDate.setMonth(purchaseDate.getMonth() + Number(interval)));
    let date = this.fillZero(calcExpiryDate.getDate());
    let month = this.fillZero(calcExpiryDate.getMonth()+1)  ;
    return `${date}/${month}/${calcExpiryDate.getFullYear()}`;
  }

  getVenueInterval(interval:String) {
    const venueInterval = VENUE_INTERVAL;
    const getIntervalName = venueInterval.find(option => option.convertedVal == Number(interval));
    return getIntervalName ? `(${getIntervalName.value})` : '';
  }

  changeStatus(user_id:String, status:Boolean){
    let updatedStatus=status?false:true;    
    this.webService.updateStatus(user_id,updatedStatus).subscribe(res=>{
      setTimeout(()=>{        
        location.reload();        
      },1000);      
      this.toastr.success(res.message);
    },err =>{
      this.toastr.error(err.message);
    })
    
  }

  changeVenueSubscrFormStatusModal(userId:String, status:number) {
    this.venueSubscrStatus = status;
    this.modalTitle = status == 1 ? 'Activate' : 'Pause';
    (<any>$('#openModal')).modal('show');
  }

  toggleModal(id:string) {
    (<any>$(`#${id}`)).modal('toggle');
  }

  updateVenueSubscrFormStatus(userId:String, status:number) {
    const payload = {
      status,
      user_id: userId,
      comment: this.comment,
    }
    this.venueSubscrService.updateVenueSubscrStatus(payload).subscribe(res=>{
      // this.changeSubscrFormLayout(status);  
      this.toggleModal('openModal');     
      this.toastr.success(res.message); 
      setTimeout(()=>{        
        location.reload();        
      },1000);
    },err =>{
      this.toastr.error(err.message);
    })
  }

  onReasonTextChange(commentText: any) {
    // trim text
    this.comment = commentText.trimStart();
  }

  getClassName(val: number) {
    return val == 2 ? 'btn btn-danger' : 'btn btn-success';
  }

  changeSubscrFormLayout(status:number) {
    this.venueSubscriptionBtn = status == 1 ? "Pause V Subscription" : "Activate V Subscription";
    this.updateSubscrBtnClass = status == 1 ? 'btn btn-danger' : 'btn btn-success';
    this.updateSubscrStatusTo = status == 1 ? 2 : 1;
    if(status == 2) {
      this.subscriptionForm.controls.purchaseDate.disable();
      this.subscriptionForm.controls.fullfillmentDate.disable();
      this.subscriptionForm.controls.expirationDate.disable();
      this.subscriptionForm.controls.package.disable();
    } else {
      this.subscriptionForm.controls.purchaseDate.enable();
      this.subscriptionForm.controls.fullfillmentDate.enable();
      this.subscriptionForm.controls.expirationDate.enable();
      this.subscriptionForm.controls.package.enable();
    }
  }

  downloadPdf(elem:string){
    var count=0;
    var data = document.getElementById(elem);
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 210; 
      var pageHeight = 295;  
      var imgHeight = canvas.height * imgWidth / canvas.width;
      console.log(imgHeight);
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm'); // A4 size page of PDF
      var position = 0;
      
      var fileName=localStorage.getItem('userName')+'_'+localStorage.getItem('phone');
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(fileName+'.pdf'); // Generated PDF
  });
  
  }

  downloadPdf2(elem:string){
    var data = document.getElementById(elem);
    html2canvas(data).then(canvas => {
      
      // Few necessary setting options
      var imgWidth = 210; 
      var pageHeight = 295;  
      var imgHeight = canvas.height * imgWidth / canvas.width;
      console.log(imgHeight);
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm'); // A4 size page of PDF
      var position = 0;
      
      var fileName=localStorage.getItem('userName')+'_'+localStorage.getItem('phone');
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(fileName+'_1.pdf'); // Generated PDF
      
  });  
  this.downloadPdf3('html-pdf3');
  
  }

  downloadPdf3(elem:string){
    var data = document.getElementById(elem);
    html2canvas(data).then(canvas => {
      
      // Few necessary setting options
      var imgWidth = 210; 
      var pageHeight = 295;  
      var imgHeight = canvas.height * imgWidth / canvas.width;
      console.log(imgHeight);
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm'); // A4 size page of PDF
      var position = 0;
      
      var fileName=localStorage.getItem('userName')+'_'+localStorage.getItem('phone');
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      // heightLeft -= pageHeight;
      // while (heightLeft >= 0) {
      //   position = heightLeft - imgHeight;
      //   pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      //   heightLeft -= pageHeight;
      // }

      pdf.save(fileName+'_2.pdf'); // Generated PDF
      
  });  
  
  }

}
